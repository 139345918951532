module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Caja de Compensación Familiar de Antioquia","short_name":"Comfama","start_url":"/","background_color":"#E62E7B","theme_color":"#E62E7B","display":"standalone","icon":"src/assets/images/logos/comfama/comfama_pwa1.svg","include_favicon":false,"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"356f14f45ba58a11f79970a88dbbeb40"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/home/vsts/work/1/s/Comfama.Portal.Home/src/service-workers/firebase-sw.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase-messaging/gatsby-browser.js'),
      options: {"plugins":[],"config":{"apiKey":"AIzaSyCYKqp3aPr7LqdmvVy7ZdAUwwsEmHaXRnA","appId":"1:842552250254:web:dfbee67281478b0f8f9edf","messagingSenderId":"842552250254","projectId":"portal-comfama"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
